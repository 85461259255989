import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import img from '../assets/images/background-home-min.jpg'
import instagram from '../assets/images/Instagram.svg'
import { animateScroll as scroll } from 'react-scroll'

const Loading = () => {
    const [position, setPosition] = useState('fixed')

    useEffect(() => {
        setTimeout(() => {
            setPosition('relative')
        }, 4000)
    }, [])

    const title = {
        visible: {
            opacity: 1,
            transition: { duration: 2, delay: 1 },
        },
        hidden: {
            opacity: 0
        }
    }

    const paragraph = {
        visible: {
            opacity: 1,
            transition: { duration: 1, delay: 4 },
            y: 0
        },
        hidden: {
            opacity: 0,
            y: 5
        }
    }

    const horizontal = {
        visible: {
            x: 0,
            transition: { duration: 1.5, delay: 0.5 },
        },
        hidden: {
            x: "-100%"
        }
    }

    const vertical = {
        visible: {
            y: 0,
            transition: { duration: 1.5 },
        },
        hidden: {
            y: "100%"
        }
    }

    const background = {
        visible: {
            x: 0,
            transition: { duration: 1.5, delay: 2 },
        },
        hidden: {
            x: "120%"
        }
    }

    return (
        <div className={`${position} h-screen w-full`}>
            <div className='absolute p-[5%] lg:p-[10%] z-20'>
                <motion.h1 animate="visible" initial="hidden" variants={title} className='text-white'>Tennis Club<br /><i>Domdidier</i></motion.h1>
                <motion.p animate="visible" initial="hidden" variants={paragraph} className='text-white z-20 w-12/12 lg:w-10/12 mt-10'>Dans la commune de Belmont-Broye notre club vous offre la possibilité de jouer au tennis au sein d’une atmosphère conviviale. Compétiteur ou joueur amateur, junior ou senior, le tennis club de Domdidier vous accueille sur 3 courts ouverts toute l’année.</motion.p>
            </div>
            <motion.div animate="visible" initial="hidden" variants={horizontal} className='absolute h-2 bg-white top-2/3 right-[10vw] w-full z-0'></motion.div>
            <motion.div animate="visible" initial="hidden" variants={vertical} className='absolute h-screen w-2 bg-white right-[10vw] z-0 hide-vertical'></motion.div>
            <motion.a target="_blank" href='https://www.instagram.com/tc_domdidier/' animate="visible" initial="hidden" variants={paragraph} className='absolute z-20 bottom-0 m-6 lg:mb-10 h-[50px] ml-[5vh] lg:ml-[20vh] bg-white p-2 rounded-full'><img src={instagram} /></motion.a>
            {/* <motion.a href='#membre' animate="visible" initial="hidden" variants={paragraph} className='text-center flex justify-center items-center absolute z-20 bottom-[15%] left-0 right-0 mx-auto bg-orange w-10/12 lg:w-4/12 rounded-[60px] lg:mb-10 h-[50px] border border-orange hover:text-orange hover:bg-transparent'>Je veux devenir membre</motion.a> */}
            <div className='h-screen bg-green w-full lg:pt-10'>
                <motion.div animate="visible" initial="hidden" variants={background} className='h-full w-full overflow-hidden lg:rounded-tr-[15vw] z-10'>
                    <img className='z-10 object-cover h-screen w-full xl:h-full' src={img} />
                </motion.div>
            </div>
        </div>
    )
}

export default Loading;