import React from 'react'
import arrow from '../assets/images/arrow.svg'
import { animateScroll as scroll } from 'react-scroll'
import { BrowserView } from 'react-device-detect'

export default function Footer() {
    return (
        <footer className='bg-dark'>
            <div className='bg-rose relative'>
                <BrowserView>
                    <a onClick={() => scroll.scrollToTop()} className='w-[100px] h-[100px] absolute top-[50%] bottom-[50%] right-[6%] bg-grey flex flex-col items-center justify-center p-6 rounded-[50%] m-auto cursor-pointer z-40'>
                        <p className='text-center text-green leading-8'>GO TOP</p>
                    </a>
                </BrowserView>
                <div className='grid grid-cols-12 bg-dark w-full h-full rounded-br-[15vw] px-[5%] lg:px-[10%] gap-4'>
                    <div className='h-2 bg-rose absolute top-0 left-0 right-[10%] hidden md:block'></div>
                    <div className='col-span-11 flex flex-col lg:flex-row text-grey justify-between relative p-4'>
                        <div className='lg:w-3/12 py-4'>
                            <h3 className='text-rose text-[32px] lg:mb-10'>TC Domdidier</h3>
                            <p>Rte de l'industrie</p>
                            <p>1564 Domdidier</p>
                            <p>Suisse</p>
                        </div>
                        <div className='lg:w-3/12 py-4'>
                            <h3 className='text-rose text-[32px] lg:mb-10'>Contact</h3>
                            <a className='hover:text-white' href='mailto:info@tcdomdidier.ch'>info@tcdomdidier.ch</a>
                        </div>
                        <div className='lg:w-3/12 py-4'>
                            <h3 className='text-rose text-[32px] lg:mb-10'>Autre</h3>
                            <a className='hover:text-white flex flex-row items-center justify-between' target="_blank" href='https://www.instagram.com/tc_domdidier/'>Instagram<img className='w-8 h-8' src={arrow} /></a>
                            <a className='hover:text-white flex flex-row items-center justify-between' target="_blank" href='https://tab-academy.ch/'>TAB<img className='w-8 h-8' src={arrow} /></a>
                        </div>
                    </div>
                    <div className='col-start-12 flex flex-row'>
                        <div className='h-full w-2 bg-rose'></div>
                        <div className='bg-dark h-2 w-full z-10 hidden md:block'></div>
                    </div>
                </div>
            </div>
        </footer>
    )
}