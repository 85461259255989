import React, { useState, useEffect } from 'react'
import { motion, useAnimation, useTransform, useViewportScroll } from 'framer-motion'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const Navbar = () => {
    const controls = useAnimation()
    const controlsMobile = useAnimation()
    const controlsBtnMobile = useAnimation()
    const [open, setOpen] = useState(false)
    const navigation = [{ title: 'Accueil', link: 'accueil' }, { title: 'Devenir membre', link: 'membre' }, { title: 'Tarifs', link: 'prix' }, { title: 'News', link: 'infos' }, { title: 'Agenda', link: 'agenda' }, { title: 'Contact', link: 'contact' },{ title: 'Nos infrastructures', link: 'infra' }, { title: 'Le comité', link: 'team' }, { title: 'F.A.Q', link: 'faq' }]
    const [currentMenu, setCurrentMenu] = useState('accueil')
    const { scrollYProgress } = useViewportScroll()
    const opacity = useTransform(scrollYProgress, [0, 0.1], [0, 1])


    const handleClickMenu = () => {
        var tmp = !open
        setOpen(tmp)
        openCloseMenu(tmp)
    }

    const openCloseMenu = (status) => {
        if (status) {
            controls.start({
                x: 0,
                visibility: 'visible',
            })
        } else {
            controls.start({
                x: '100%',
            })
        }
    }

    const buttonAnimation = {
        visible: {
            opacity: 1,
            transition: { duration: 2, delay: 4 }
        },
        hidden: {
            opacity: 0
        }
    }

    const openMobileMenu = () => {
        controlsMobile.start({
            height: '100%',
            borderTopLeftRadius: 0,
            transition: { duration: 1 }
        })
        controlsBtnMobile.start({
            visibility: 'visible'
        })
    }

    const closeMobileMenu = () => {
        controlsMobile.start({
            transition: { duration: 1 },
            height: '0%',
            borderTopLeftRadius: '100%',
        })
        controlsBtnMobile.start({
            visibility: 'hidden',
            transition: { delay: 1 }
        })
    }

    const browserView = () => {
        return <BrowserView>
            <div className='z-50'>
                <motion.div variants={buttonAnimation} initial="hidden" animate="visible">
                    <button onClick={handleClickMenu} className='absolute right-0 top-0 z-50 rounded-full bg-orange w-12 h-12 m-6' />
                </motion.div>
                <div className='hidden absolute right-0 top-0 bg-green pt-[30vh] h-[15vw] w-[15vw] z-40'></div>
                <motion.div className='absolute justify-end flex bottom-0 top-0 mt-10 right-0 z-30 overflow-hidden' animate={controls} initial={{ visibility: 'hidden' }}>
                    <ul className='bg-grey w-[15vw] h-full p-4 rounded-tr-[15vw] pt-[20vh] text-green z-30 relative'>
                        {navigation.map((item, i) =>
                            <li className='ml-2 mt-5 flex flex-row items-center'><a onClick={() => { handleClickMenu(); setCurrentMenu(item.link) }} href={'#' + item.link} className='text-[1rem]'>{item.title}</a>{(currentMenu === item.link) ? <span className='w-4 h-4 block bg-green rounded-full ml-6'></span> : null}
                            </li>
                        )}
                    </ul>
                </motion.div>
            </div>
        </BrowserView>
    }

    const mobileView = () => {
        return <MobileView>
            <div className='z-50'>
                <motion.div style={{opacity: opacity}}>
                    <button onClick={openMobileMenu} className='fixed drop-shadow-sm right-0 bottom-0 z-50 rounded-full bg-orange w-12 h-12 m-6' />
                </motion.div>
                <motion.div animate={controlsMobile} className='z-[60] w-screen h-0 fixed bottom-0 bg-orange'>
                    <ul className="h-full p-4 text-green z-30">
                        {navigation.map((item, i) =>
                            <li className='ml-5 mt-10 flex flex-row items-center'><a onClick={() => { handleClickMenu(); setCurrentMenu(item.link); closeMobileMenu() }} href={'#' + item.link} className='text-[20px]'>{item.title}</a>{(currentMenu === item.link) ? <span className='w-4 h-4 block bg-green rounded-full ml-6'></span> : null}
                            </li>
                        )}
                    </ul>
                    <motion.button animate={controlsBtnMobile} initial={{ visibility: 'hidden' }} onClick={closeMobileMenu} className='absolute right-0 bottom-0 z-50 rounded-full bg-green w-12 h-12 m-6' />
                </motion.div>
            </div>
        </MobileView>
    }

    return (
        <div>
            {browserView()}
            {mobileView()}
        </div>
    )
}

export default Navbar