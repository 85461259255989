import React from 'react'
import image from '../assets/images/member-min.jpg'

const Member = () => {
    return (
        <section id="membre" className='bg-green py-[10%] p-[5%] lg:p-[10%] lg:h-screen flex items-center'>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 lg:col-span-7'>
                    <img src={image} />
                    <h2 className='-mt-20 lg:-mt-[15%] text-white-rose'>Deviens<br />membre du club</h2>
                </div>
                <div className='col-span-12 lg:col-span-4 lg:col-start-9 flex flex-col'>
                    <p className='text-white'>Inscris-toi pour profiter de nos installations toute l’année et vivre des moments de convivialités lors de nos activités.</p>
                    <a target="_blank" href='https://reservation.tcdomdidier.ch/user/signup' className='w-full border border-orange bg-orange h-[50px] rounded-[60px] mt-20 flex items-center justify-center cursor-pointer hover:text-orange hover:bg-green hover:border-orange'>Je veux devenir membre</a>
                    <a href='#prix' className='w-full text-white h-[50px] rounded-[60px] border border-white mt-10 flex items-center justify-center cursor-pointer hover:text-green hover:bg-white'>Voir les tarifs</a>
                </div>
            </div>
        </section>
    )
}


export default Member;