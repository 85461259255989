import React, { useState, useEffect } from 'react'
import sanityClient from '../sanityClient'
import visitIcon from '../assets/images/downloadicon.svg'
import downlaodIcon from '../assets/images/downloadicon-doc.svg'

export default function UsefulLink() {
    const [links, setLinks] = useState([])

    useEffect(() => {
        sanityClient.fetch(`*[_type == "useful_link"]{
            title,
          url,
          type,
          "fileUrl":file.asset->url
        }`).then((data) => {
            setLinks(data)
        }).catch(e => console.log(e))
    }, [])

    const getBtnLink = (type) => {
        if (type === 'website') {
            return <div className='flex flex-row items-center lg:mr-20'><p className='opacity-40 hidden lg:block'>Visiter</p><img src={visitIcon} className="ml-10" /></div>
        } else if (type === 'doc') {
            return <div className='flex flex-row items-center lg:mr-20'><p className='opacity-40 hidden lg:block'>Télécharger</p><img src={downlaodIcon} className="ml-10" /></div>
        }
    }

    const getLinks = () => {

        return links.map((item, i) => {
            var link = (item.url !== undefined) ? item.url : item.fileUrl;
            console.log(link)
            return <a target="_blank" href={link} className='py-10 flex flex-row items-center justify-between text-rose w-full border-b-rose border-b-[3px] hover:text-white'>
                <p>{item.title}</p>
                {getBtnLink(item.type)}
            </a>
        })

    }

    return (
        <section id="useful_link" className='h-full bg-dark'>
            <div className='grid grid-cols-12 lg:px-[10%] px-[5%] gap-4'>
                <div className='col-span-11 lg:col-start-1 lg:col-end-8 lg:col-span-8 py-[10%]'>
                    <h2 className='text-[3rem] lg:-ml-20 text-rose'>Liens utiles</h2>
                    {getLinks()}
                </div>
                <div className='col-start-12'>
                    <div className='w-2 h-full bg-rose'></div>
                </div>
            </div>
        </section>
    )
}