import React from 'react'
import { Routes, useLocation, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Onepage from '../pages/Onepage';

export default function AnimatedRoutes() {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Onepage />} />
            </Routes>
        </AnimatePresence>
    )
}