import { motion, useAnimation } from "framer-motion";
import React, { useState, useEffect } from "react";
import sanityClient from "../sanityClient";

const Team = () => {
    const [teams, setTeams] = useState([])
    const pos = ["left-0 top-10", "right-0 top-0", "bottom-10 left-0", "bottom-20 right-0", "bottom-0 top-0 left-0 right-0", "-bottom-5 left-0 right-0"]
    const rounded = ["rounded-tl-[50%]", "rounded-br-[50%]", "rounded-bl-[50%]", "rounded-br-[50%]", "","rounded-br-[50%]"]
    const controls = useAnimation()

    useEffect(() => {
        sanityClient.fetch(`*[_type == "team"]{
            name,
            firstname,
            function,
            "imageUrl": image.asset->url
        }`).then(data => {
            setTeams(data)
        }).catch(e => {
            console.log(e)
        })
    }, [])

    const moveForward = (current) => {
        controls.start(i => ({
            zIndex: (i === current) ? 99 : 1
        }))
    }

    const moveBackward = () => {
        controls.start({
            zIndex: 1
        })
    }

    const getTeamsImg = () => {
        return teams.map((item, i) =>
            <motion.div custom={i} onHoverStart={() => moveForward(i)} onHoverEnd={moveBackward} animate={controls} className={`absolute ${pos[i]} h-fit w-fit m-auto`}>
                <div class="flip-box w-[120px] h-[140px] lg:w-[200px] lg:h-[220px]">
                    <div class="flip-box-inner">
                        <div class="flip-box-front">
                            <img src={item.imageUrl} className={`object-cover w-full h-full ${rounded[i]}`} />
                        </div>
                        <div class="flip-box-back">
                            <img src={item.imageUrl} className="object-cover w-full h-full" />
                            <div className="absolute text-[16px] md:text-[20px] inset-0 w-full text-rose bg-green opacity-90 flex flex-col justify-center items-center">
                                <p>{item.name} {item.firstname}</p>
                                <p>{item.function}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    }


    return (
        <section id="team" className="bg-rose h-full">
            <div className="lg:grid lg:grid-cols-12 p-[5%] lg:p-[10%] gap-4 flex flex-col">
                <div className="lg:col-start-1 lg:col-end-5 lg:col-span-5 text-green">
                    <h2 className="text-[3rem] lg:-ml-20 mb-10 leading-10">Rencontrez le comité</h2>
                    <p>
                        Le comité du Tennis Club de Domdidier est composé de 5 membres. Ils se rencontrent 5 à 6 fois par année pour organiser les différentes manifestations et assurer le bon fonctionnement de nos infrastructures.
                    </p>
                    <br />
                    <p>Tout membre au sein de notre organisation peut rejoindre le comité. Il est important d’avoir des personnes motivées pour faire vivre notre club.
                    </p>
                </div>
                <div className="lg:col-start-7 lg:col-end-12 lg:col-span-5 relative h-[420px] w-full lg:h-[650px] lg:w-auto">
                    {getTeamsImg()}
                </div>
            </div>
        </section>
    )
}

export default Team