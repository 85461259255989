import React, { useState } from 'react'
import Ball from '../assets/images/ball-btn.svg'
import { useAnimation, motion } from 'framer-motion'

const ButtonSlide = (props) => {
    const controlsBall = useAnimation()
    const controlsBtn = useAnimation()
    const controlsTxt = useAnimation()
    const [sendTxt, setSendTxt] = useState('Envoyer')

    const clickBtn = async () => {
        if (props.validate() && props.send()) {
            controlsBall.start({
                left: "calc(100% - 47px)",
                transition: { duration: 2 },
                rotate: "360deg",
                backgroundColor: "#173930"
            })

            controlsBtn.start({
                backgroundColor: "#EAC7B7",
                width: "100%",
                transition: { duration: 2 },
            })

            await controlsTxt.start({
                opacity: 0,
                transition: { duration: 1 },
            })
            setSendTxt("C'est reçu !")
            controlsTxt.start({
                opacity: 1,
                color: "#173930",
                transition: { duration: 1 },
            })
        }
    }

    return (
        <button className='relative mt-10 lg:mt-0 w-full lg:w-[300px] h-[50px] flex justify-center items-center relative border-rose rounded-[60px] bg-rose overflow-hidden' onClick={clickBtn}>
            <div className='w-[99%] lg:w-[296px] h-[46px] bg-green rounded-[60px] relative flex flex-row justify-center items-center'>
                <motion.div animate={controlsBtn} initial={{ width: "0px" }} className='absolute w-[300px] h-[50px] left-0 rounded-r-[60px]'></motion.div>
                <motion.div animate={controlsBall} className='ball-btn absolute -left-[3px] h-[50px] w-[50px] z-10 m-auto' />
                <motion.p animate={controlsTxt} className='text-rose z-10'>{sendTxt}</motion.p>
            </div>
        </button>
    )
}
export default ButtonSlide