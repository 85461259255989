import React from "react"
import clubhouse from "../assets/images/clubhouse.jpg"
import bar from "../assets/images/bar-tcd.jpg"
import court from "../assets/images/court.jpg"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper";

export default function Infra() {
    return (
        <section id="infra" className="h-full md:min-h-screen bg-grey">
            <div className="flex flex-col justify-between md:flex-row md:items-center h-full w-full">
                <div className="md:w-7/12 w-full p-10 lg:p-20">
                    <h2 className="text-green text-[60px]">Nos infrastructures</h2>
                    <p className="text-green">
                        Le Tennis Club de Domdidier dispose de 3 smash courts extérieurs disponibles toute l'année. Un magnifique clubhouse tout équipé est à disposition pour les après-matchs.                    </p>
                    <br />
                    <p className="text-green">
                        Nos membres jouissent de nos infrastructures de manière autonome grâce à système de réservation en ligne et un code d'accès personnel.                    </p>
                </div>
                <div className="h-full flex justify-end md:items-center w-full md:w-5/12 md:h-screen">
                    <Swiper
                        direction="horizontal"
                        centeredSlides={true}
                        spaceBetween={10}
                        initialSlide={1}
                        autoplay={true}
                        modules={[Autoplay]}
                        slidesPerView={1}
                        className="cursor-grab"
                    >
                        <SwiperSlide>
                            <img src={clubhouse} className="object-cover w-full h-full" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={court} className="object-cover w-full h-full" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={bar} className="object-cover w-full h-full" />
                        </SwiperSlide>
                        {/* <img src={clubhouse} className="object-cover w-full h-1/2" />
                        <div className="flex flex-row h-1/2">
                            <img src={court} className="object-cover w-1/2" />
                            <img src={bar} className="object-cover w-1/2" />
                        </div> */}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}