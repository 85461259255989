import React from "react";
import Navbar from '../components/Navbar';
import Loading from '../components/Loading';
import { motion } from 'framer-motion';
import Member from '../components/Member';
import Price from '../components/Price';
import Agenda from '../components/Agenda';
import Contact from '../components/Contact';
import Team from '../components/Team';
import UsefulLink from '../components/UsefulLink';
import Footer from '../components/Footer';
import Faq from '../components/Faq';
import Bucket from "../components/Bucket";
import Breaking from "../components/Breaking";
import Infra from "../components/Infra";

export default function Onepage() {

    const content = {
        visible: {
            visibility: 'visible',
            transition: { delay: 4 }
        },
        hidden: {
            visibility: 'hidden'
        }
    }



    return (
        <motion.div className="bg-grey w-full h-full overflow-hidden">
            <Loading />
            <motion.div variants={content} animate="visible" initial="hidden" exit={{ x: "-100%", transition: { duration: 1 } }}>
                <Navbar />
                <Breaking />
                <Member />
                <Price />
                <Bucket />
                <Agenda />
                <Contact />
                <Infra/>
                <Team />
                <Faq />
                <UsefulLink />
                <Footer />
            </motion.div>
        </motion.div>
    )
}