import React, { useState, useEffect, useRef } from "react";
import sanityClient from "../sanityClient";
import { PortableText } from "@portabletext/react";
import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation } from "swiper";
import Arrow from '../assets/images/arrow-bottom.svg'
import { BrowserView, isMobile } from 'react-device-detect';

function Bucket() {
    const [infos, setInfos] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)
    const swiperRef = useRef(null);
    const nextEvent = useRef(null)
    const prevEvent = useRef(null)

    useEffect(() => {
        getInfos()
    }, [])

    const getInfos = () => {
        sanityClient.fetch(`*[_type == "bucket"]{
            title,
            description,
            "imageUrl": image.asset->url,
            position
        } | order(position asc)`).then(data => {
            setInfos(data)
        }).catch(e => {
            console.log(e)
        })
    }

    const infoDescription = {
        block: {
            normal: ({ children, value }) => <p className="text-green">{children}</p>
        },
        marks: {
            link: ({ children, value }) => {
                const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
                return (
                    <a className="text-green underline" href={value.href} rel={rel} target="_blank">
                        {children}
                    </a>
                )
            },
        },
    }

    const getInfoSlot = () => {
        return infos.map((item, i) => {
            return <SwiperSlide className="flex flex-col">
                <div className="h-[200px] md:h-[300px]">
                    <img className="object-cover h-full w-full" src={item.imageUrl} />
                </div>
                <div>
                    <h4 className="text-green text-[20px] leading-[30px] lg:text-[3rem] lg:leading-[3rem] my-4">{item.title}</h4>
                    <PortableText
                        value={item.description}
                        components={infoDescription}
                    />
                </div>
            </SwiperSlide>
        })
    }

    const getPagination = () => {
        return infos.map((item, i) => {
            if (i === activeIndex) {
                return <div className="bg-green w-4 h-4 rounded-full mx-2"></div>
            } else {
                return <div onClick={() => swipeToIndex(i)} className="bg-white w-4 h-4 rounded-full mx-2 cursor-pointer"></div>
            }
        })
    }

    const swipeToIndex = (i) => {
        swiperRef.current.swiper.slideTo(i)
    }

    if (infos.length > 0) {
        return (
            <section id="infos">
                <div className="h-full py-[10%] bg-grey flex flex-col justify-center">
                    <div className="w-full lg:w-11/12 self-end">
                        <Swiper ref={swiperRef}
                            className="cursor-grab"
                            direction="horizontal"
                            centeredSlides={true}
                            spaceBetween={(isMobile) ? 20 : 50}
                            initialSlide={1}
                            modules={[Navigation]}
                            slidesPerView={(isMobile) ? 2 : 3}
                            navigation={{
                                nextEl: nextEvent.current,
                                prevEl: prevEvent.current
                            }}
                            onSlideChange={(e) => setActiveIndex(e.activeIndex)}>
                            {getInfoSlot()}
                        </Swiper>
                    </div>
                    <BrowserView>
                        <div className="flex justify-between mt-10 items-center w-10/12 mx-auto">
                            <a ref={prevEvent}><img className="rotate-90 cursor-pointer" src={Arrow} /></a>
                            <div className="flex justify-center">
                                {getPagination()}
                            </div>
                            <a ref={nextEvent}><img className="cursor-pointer -rotate-90" src={Arrow} /></a>
                        </div>
                    </BrowserView>
                </div>
            </section>
        )
    } else {
        return null;
    }
}

export default Bucket;