import React, { useEffect, useRef, useState } from 'react'
import Ball from '../assets/images/ball.svg'
import BallShadow from '../assets/images/ball-shadow.svg'
import { motion, useTransform, useViewportScroll, useElementScroll, useMotionValue } from 'framer-motion'
import { useInView } from "react-intersection-observer";
import sanityClient from '../sanityClient';
import { BrowserView, MobileView } from 'react-device-detect';
const colors = ["bg-[#D7DEDB]", "bg-[#173930]", "bg-[#F7E1DA]", "bg-[#EAC7B7]", "bg-[#D7DEDB]"]

const Price = () => {

    const [prices, setPrices] = useState([])
    const [ref, inView, entry] = useInView()
    const { scrollYProgress, scrollY } = useViewportScroll()
    const bounce = useRef(false)
    const oldY = useRef(0)
    const deltaY = useRef(0)
    const deltaX = useRef(0)
    const scrollValue = useRef(0)
    const moveX = useTransform(scrollYProgress, x => -x * deltaX.current)

    //new 
    const treeshold = 0.5;
    const y = useTransform(scrollYProgress, [0.25, treeshold], ['0vw', '60vw'])
    const xLeft = useTransform(scrollYProgress, [0.25, treeshold], ['0vw', '60vw'])

    // const moveY = useTransform(scrollY, y => {
    //     return y / 4
    // })

    useEffect(() => {
        sanityClient.fetch(`*[_type == "price"]{
            title,
          price,
          position
        } | order(position asc)`).then((data) => {
            setPrices(data)
        }).catch(e => console.log(e))
    }, [])

    const getPriceView = () => {
        return prices.map((item, i) => {
            var txtColor = (i === 1) ? 'text-white' : 'text-black'
            return <div className={`${colors[i]} ${txtColor} rounded-[150px] w-full flex items-center flex-col justify-center h-[50px] lg:h-[15vh] my-4 py-8`}>
                <p>{item.title}</p>
                <p>{item.price} CHF / AN</p>
            </div>
        })
    }

    const moveY = useTransform(scrollYProgress, y => {
        if (bounce.current) {
            return oldY.current - (y * deltaY.current - oldY.current)
        } else {
            oldY.current = y * deltaY.current
            return y * deltaY.current
        }
    });

    useEffect(() => {
        scrollY.onChange(() => {
            const ball = document.getElementById('ball').getBoundingClientRect();
            const shadow = document.getElementById('shadow').getBoundingClientRect();
            if (Math.round(ball.y) === Math.trunc(shadow.y)) {
                console.log('bounce', ball.y, " : ", shadow.y)
                bounce.current = !bounce.current
                return
            }
        })
    }, [])

    // scrollY.onChange((latest) => {
    //     const ball = document.getElementById('ball').getBoundingClientRect();
    //     const shadow = document.getElementById('shadow').getBoundingClientRect();
    //     if (Math.trunc(ball.y) === Math.trunc(shadow.y)) {
    //         console.log('bounce', Math.trunc(ball.y), " : ", Math.trunc(shadow.y))
    //     }
    // if (bounce.current) {
    //     if (circleIntersect(ball.x, ball.y, ball.width / 2, shadow.x, shadow.y, shadow.width / 3)) {
    //         //bounce.current = false
    //     }
    // }
    // })

    useEffect(() => {
        if (inView) {
            deltaY.current = 800
            deltaX.current = 1200
        }
    }, [inView])

    const circleIntersect = (x1, y1, r1, x2, y2, r2) => {
        // Calculate the distance between the two circles
        let squareDistance = (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2);

        // When the distance is smaller or equal to the sum
        // of the two radius, the circles touch or overlap
        return squareDistance <= ((r1 + r2) * (r1 + r2))
    }

    return (
        <section id="prix" className='bg-orange h-full lg:min-h-screen relative flex flex-col justify-center'>
            <BrowserView>
                <motion.img ref={ref} id='ball' className='absolute top-0 right-0 z-10 w-[5vw] h-[5vw]' initial={{ x: -100 }} style={{ x: moveX, y: moveY }} src={Ball} />
                <motion.img id='shadow' className='absolute right-0 top-1/2 w-[5vw] h-[5vw]' style={{ x: moveX }} src={BallShadow} />
            </BrowserView>
            <div className='px-[5%] lg:px-[10%] h-full'>
                <div className='grid grid-cols-12 h-full gap-4'>
                    <div className='col-span-11 lg:col-span-5 flex flex-col justify-center py-[5%]'>
                        {getPriceView()}
                        <MobileView className='h-[200px] w-full flex flex-col justify-between'>
                            <motion.img style={{ x: xLeft, y: y }} id='ball' className='z-10 w-[10vw] h-[10vw] md:w-[5vw] md:h-[5vw]' src={Ball} />
                            <motion.img style={{ x: xLeft }} initial={{ x: '0%' }} id='shadow' className='w-[10vw] h-[10vw] md:w-[5vw] md:h-[5vw]' src={BallShadow} />
                        </MobileView>
                    </div>
                    <div className='col-start-12'>
                        <div className='w-2 h-full lg:h-screen bg-white'></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Price