import React, { useEffect, useRef, useState } from 'react'
import { useViewportScroll, useTransform, motion, useMotionValue, useAnimation } from 'framer-motion'
import arrowback from '../assets/images/arrowback.svg'
import { Link } from 'react-router-dom'
import { ScrollElement } from 'react-scroll'
import FaqContent from './Faq-content'
export default function Faq() {
    const { scrollYProgress } = useViewportScroll()
    const [blocked, setBlocked] = useState('sticky top-0')
    const title = useRef();
    const treeshold = 0.9;
    const xRight = useTransform(scrollYProgress, [0.6, treeshold], ['100%', '0%'])
    const xLeft = useTransform(scrollYProgress, [0.6, treeshold], ['-100%', '0%'])
    const faqControls = useAnimation()


    const displayFaq = () => {
        faqControls.start({
            x: 0,
            transition: { duration: 1 }
        })
    }

    const hideFaq = () => {
        faqControls.start({
            x: "100%",
            transition: { duration: 1 }
        })
    }




    return (
        <section id="faq" className={`bg-grey h-full z-50 relative min-h-screen`}>
            <div className='text-grey-light flex flex-col w-full justify-center items-center h-full mt-20'>
                <motion.h3 ref={title} style={{ x: xRight }} initial={{ x: '100%' }} className='text-[70px] leading-[84px] lg:text-[16rem] lg:leading-[16rem]'>Foire aux</motion.h3>
                <motion.h3 style={{ x: xLeft }} initial={{ x: '-100%' }} className='text-[70px] leading-[84px] lg:text-[16rem] lg:leading-[16rem]'>questions</motion.h3>
                <button onClick={displayFaq} className='my-20 lg:py-2 h-[50px] lg:h-[100px] lg:h-auto w-11/12 lg:w-[500px] border border-green rounded-[60px] text-green flex flex-row items-center justify-center relative'>Accéder à la FAQ<img className='w-4 h-4 absolute right-10' src={arrowback} /> </button>
            </div>
            <motion.div initial={{ x: "100%" }} animate={faqControls} className='fixed bg-grey h-full w-full z-[60] top-0 bottom-0'>
                <FaqContent hide={hideFaq} />
            </motion.div>
        </section>
    )
}