import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom"
import sanityClient from "../sanityClient";
import arrowDown from "../assets/images/arrowdown.svg"
import arrowBack from "../assets/images/arrowback.svg"
import { PortableText } from '@portabletext/react'
import emoji1 from '../assets/images/emoji-man.svg'
import emoji2 from '../assets/images/emoji-boy.svg'
import emoji3 from '../assets/images/emoji-girl.svg'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Navbar from "./Navbar";
import { Element } from "react-scroll";

export default function FaqContent(props) {
    const [questions, setQuestions] = useState([])
    const controls = useAnimation();
    const answerControl = useAnimation();
    const openQuestions = useRef([]);
    const arrowControl = useAnimation();
    const emojis = [emoji1, emoji2, emoji3];

    useEffect(() => {
        //controls.start({ x: 0, transition: { delay: 1, duration: 2 } })
        getFaq();
    }, [])

    const getFaq = () => {
        sanityClient.fetch(`*[_type == "faq"]{
            question,
          answer
        }`).then(data => {
            console.log(data)
            setQuestions(data)
        }).catch(e => {
            console.log(e)
        })
    }


    const openAnswer = async (current) => {
        if (!openQuestions.current[current]) {
            arrowControl.start(i => ({
                rotate: (i === current) ? '180deg' : '0deg',
            }))
            await answerControl.start(i => ({
                height: (i === current) ? 'auto' : 0,
                opacity: 0,
                transition: { ease: 'easeIn' }
            }))
            answerControl.start(i => ({
                visibility: (i === current) ? 'visible' : 'hidden',
                opacity: 1,
                transition: { ease: 'easeIn' }
            }))
            openQuestions.current[current] = true
        } else {
            arrowControl.start(i => ({
                rotate: '0deg',
            }))
            await answerControl.start(i => ({
                visibility: (i === current) ? 'hidden' : 'hidden',
                opacity: 1,
                transition: { ease: 'easeIn' }
            }))
            answerControl.start(i => ({
                height: (i === current) ? 0 : 0,
                opacity: 0,
                transition: { ease: 'easeIn' }
            }))
            openQuestions.current[current] = false

        }
    }


    const answerDescription = {
        block: {
            normal: ({ children, value }) => <p className="text-white p-4">{children}</p>
        },
        marks: {
            link: ({ children, value }) => {
                const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
                return (
                    <a className="text-white underline" href={value.href} rel={rel} target="_blank">
                        {children}
                    </a>
                )
            },
        },
    }
    const getRandomEmoji = () => {
        return Math.floor(Math.random() * (3 - 0)) + 0;
    }

    const displayFaq = () => {
        var nbRows = 0;
        var nbCols = 1;
        var mLeft = '';
        return questions.map((item, i) => {
            openQuestions.current.push(false)
            if (nbRows % 2 != 0 && isBrowser) {
                mLeft = 'ml-[100px]'
            } else {
                mLeft = ''
            }
            if (nbCols == 2) {
                mLeft = ''
                nbRows++;
                nbCols = 1;
            } else {
                nbCols++;
            }
            return <div className={`${mLeft} flex flex-row w-full xl:w-5/12 justify-between items-start my-5 md:my-10 mx-5`}>
                <div className="w-20 h-20 bg-white rounded-full mr-4 flex items-center"><img src={emojis[getRandomEmoji()]} /></div>
                <div onClick={() => openAnswer(i)} className="bg-white w-[90%] p-4 cursor-pointer rounded-[19px]">
                    <div className="flex flex-row">
                        <p>{item.question}</p>
                        <motion.img custom={i} animate={arrowControl} className="ml-4" src={arrowDown} />
                    </div>
                    <motion.p className="bg-green mt-4 text-white rounded-[19px]" custom={i} animate={answerControl} initial={{ visibility: 'hidden', height: 0 }}>
                        <PortableText
                            value={item.answer}
                            components={answerDescription}
                        />
                    </motion.p>
                </div>
            </div>
        })
    }

    return (
        <div className="h-full w-full min-h-screen bg-grey overflow-y-scroll">
            <BrowserView>
                <motion.div animate={{ opacity: 1, transition: { delay: 1, duration: 1 } }} initial={{ opacity: 0 }}>
                    <button onClick={props.hide} className="fixed z-10 left-10 m-auto top-[50%] text-green rotate-180 border border-green p-6 rounded-[50%]"><img src={arrowBack} /></button>
                </motion.div>
            </BrowserView>
            <div className="h-full">
                <div className="flex flex-row items-center h-full lg:p-[10vh]">
                    <div className="flex flex-row w-full h-full pb-[10vh] lg:m-10 flex-wrap">
                        {displayFaq()}
                    </div>
                </div>
            </div>
            <MobileView>
                <motion.div className="w-full fixed bottom-0 pt-10 flex bg-gradient-to-t via-grey from-grey p-4 z-[70]" animate={{ opacity: 1, transition: { delay: 2, duration: 1 } }} initial={{ opacity: 0 }}>
                    <button onClick={props.hide} className="text-green rotate-180 border border-green p-4 rounded-[50%]"><img src={arrowBack} /></button>
                </motion.div>
            </MobileView>
        </div>
    )
}

